import "./App.css";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/home";
import Destination from "./pages/destination/destination";
import Tours from "./pages/tours";
import TourDetails from "./pages/TourDetails/TourDetails";
import About from "./pages/about";
import Error404 from "./pages/404";
import PackageDetail from "./pages/PackageDetail";
import Blog from "./pages/blog";
import Contact from "./pages/contact";
import Login from "./pages/login";
import ForgotPassword from "./pages/ForgotPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Header from "./components/header";
import Footer from "./components/footer";
import ScrollToTopButton from "./components/shared/ScrollToTopButton";
import ComingSoon from "./pages/comingSoon";
import DashboardUsers from "./Admin/dashboard/dashboardUsers/dashboardUsers";
import DashboardDestinations from "./Admin/dashboard/dashboardDestinations/dashboardDestinations";
import DashboardTours from "./Admin/dashboard/dashboardTours/dashboardTours";
import DashboardPackages from "./Admin/dashboard/dashboardPackages/dashboardPackages";
import DashboardMain from "./Admin/dashboard/dashboardMain/dashboardMain";
import ProtectedRoute from "./components/protectedRoute";
import DashboardWishlist from "./Admin/dashboard/dashboardWishlist/dashboardWishlist";
import DashboardBookingAndEnquiry from "./Admin/dashboard/dashboardBookingAndEnquiry/dashboardBookingAndEnquiry";
import DashboardComments from "./Admin/dashboard/dashboardComments/dashboardComments";
import Signup from "./pages/Signup";
import ROLES from "./constants/roles";
import DashboardTourPage from "./Admin/dashboard/dashboardTourPage/dashboardTourPage";
import DashboardBlogs from "./Admin/dashboard/dashboardBlogs/dashboardBlogs";
import DashboardBlogPage from "./Admin/dashboard/dashboardBlogPage/dashboardBlogPage";
import BlogSingle from "./pages/blogSingle";
import { LanguageProvider } from "./context/LanguageContext";
import { SearchProvider } from "./context/SearchContext";
import DashboardReservations from "./Admin/dashboard/dashboardReservations/DashboardReservations";
import SpecialOffersPage from "./Admin/dashboard/SpecialOffers/SpecialOffersPage";
import MyReservations from "./pages/MyReservations/MyReservations";
export const notifySuccess = (message) => {
  toast.success(message, {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const notifyError = (message) => {
  toast.error(message, {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return window.localStorage.getItem("currentUser") !== null;
  });

  const [userType, setUserType] = useState(() => {
    const user = window.localStorage.getItem("currentUser");
    if (user) {
      const parsedUser = JSON.parse(user);
      return parsedUser?.roles?.[0]?.name || ""; // Safely access roles
    } else {
      return "";
    }
  });

  // Redirect based on login status and user type
  useEffect(() => {
    if (location.pathname === "/") {
      if (isLoggedIn && userType === ROLES.ADMIN) {
        navigate("/admin");
      } else {
        navigate("/home");
      }
    }
  }, [isLoggedIn, userType, location.pathname, navigate]);

  // Check if user exists on load
  useEffect(() => {
    if (userType) {
      setIsLoggedIn(true);
      setUserType(userType?.roles?.[0]?.name || ""); // Safely access roles
    } else {
      setIsLoggedIn(false);
      setUserType("");
    }
  }, [setIsLoggedIn, setUserType]);

  const isAuthPage =
    location.pathname.includes("/login") ||
    location.pathname.includes("/signup");

  return (
    <>
      <LanguageProvider>
        <SearchProvider>
          {!isAuthPage && (
            <Header isLoggedIn={isLoggedIn} userType={userType} />
          )}
          <ScrollToTop />
          <Routes>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/my-reservations/:id" element={<MyReservations />} />
            <Route path="/destination" element={<Destination />} />
            <Route path="/tours" element={<Tours />} />
            <Route path="/tours/:tourId" element={<TourDetails />} />
            <Route
              path="/tours/destination/:destinationId"
              element={<Tours />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/tours/package-detail" element={<PackageDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:blogId" element={<BlogSingle />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="*" element={<Error404 />} />

            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />

            <Route
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn} userType={userType} />
              }
            >
              <Route path="/admin" element={<DashboardMain />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/admin/login/forgot" element={<ForgotPassword />} />
              <Route path="/admin/users" element={<DashboardUsers />} />
              <Route
                path="/admin/destination"
                element={<DashboardDestinations />}
              />
              <Route path="/admin/blog" element={<DashboardBlogs />} />
              <Route
                path="/admin/blog/:blogId"
                element={<DashboardBlogPage />}
              />
              <Route path="/admin/tours" element={<DashboardTours />} />
              <Route
                path="/admin/tours/:tourId"
                element={<DashboardTourPage />}
              />
              <Route
                path="/admin/specialOffers"
                element={<SpecialOffersPage />}
              />
              <Route path="/admin/packages" element={<DashboardPackages />} />
              <Route
                path="/admin/inquiries"
                element={<DashboardBookingAndEnquiry />}
              />
              <Route
                path="/admin/reservations"
                element={<DashboardReservations />}
              />
              <Route path="/admin/wishlist" element={<DashboardWishlist />} />
              <Route path="/admin/comments" element={<DashboardComments />} />
            </Route>
          </Routes>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <ScrollToTopButton isLoggedIn={isLoggedIn} userType={userType} />
          <Footer isLoggedIn={isLoggedIn} userType={userType} />
        </SearchProvider>
      </LanguageProvider>
    </>
  );
}

export default App;

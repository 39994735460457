export default function SliderItem({ backgroundImage, title }) {
  return (
    <div>
      
      <div className="home-banner-items">
        <div
          className="banner-inner-wrap"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        <div className="banner-content-wrap">
          <div className="container-fluid">
            <div className="banner-content text-center py-5">
              <h2 className="banner-title">{title}</h2>
              {/* Home search field html start */}

            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useResolvedPath, useMatch } from 'react-router-dom';
import { IoSearchOutline } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { HiOutlineMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import "../../dashboardIndex.css";
import header from "./adminHeader.module.css";
import logo from '../../../images/Album-Travel-Logo.png';
import adminImage from "../../assets/images/users/girl6.jpg"
import { notifySuccess } from '../../../App';
import { useSearch } from '../../../context/SearchContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { api_url } from '../../../constants/base_url';

const AdminHeader = () => {
    const navigate = useNavigate()
    const [menuOpen, setMenuOpen] = useState(false);
    const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const { setSearchResults } = useSearch();
    const [searchValue, setSearchValue] = useState(' ')
    const location = useLocation();
    const queryClient = useQueryClient();


    const { data, refetch,isSuccess } = useQuery({
        queryKey: ['tour', searchValue],
        queryFn: () =>
            axios.get(`${api_url}tour/searchBycode/${searchValue}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }).then((res) => res.data),
        enabled: false, // Prevent automatic fetching, use refetch manually
        onSuccess: (data) => {
            console.log('Data fetched successfully:', data);
        },
        onError: (err) => {
            console.error('Error fetching data:', err);
        },
    });


    useEffect(() => {
        if(isSuccess){
            setSearchResults(data);
        }
        if (location.pathname.includes('admin/tours') && searchValue.trim()) {
            refetch(); // Fetch data if URL and search value are valid
        }
        if (searchValue.trim()=="") {
            setSearchResults([])
            queryClient.invalidateQueries(['tour']);
        }
    }, [location.pathname, searchValue, refetch,isSuccess]);
    
    const handleSearch = () => {
        if (searchValue.trim()) refetch(); // Trigger search manually if needed
    };


    const handleMenuToggle = (setDivOpen, divOpen) => {
        setDivOpen(!divOpen);
    };

    const handleMenuItemClick = (setDivOpen) => {
        setDivOpen(false);
        console.log("bye")
    };

    const handleLogout = () => {
        navigate("/login");
        localStorage.removeItem('currentUser');
        localStorage.removeItem('accessToken');
        notifySuccess("You Logged out successfully");
    }

    return (
        <div className={header.headerContainer}>
            <header className={header.adminHeader}>
                <div className={header.logo}><img src={logo} alt="album-travel-logo" /></div>

                <div className={header.searchContainer}>
                    <input type="text" className={header.searchInput} placeholder='Search Now'  onChange={(e) => setSearchValue(e.target.value)}/>
                    <IoSearchOutline className={header.searchIcon}  onClick={handleSearch}/>
                </div>

                <div className={header.rightSection}>
                    <FaBell className={`${header.notficationIcon} ${header.transitionAnimation}`} />
                    <div className={header.profileSection} onClick={() => handleMenuToggle(setProfileDropdownOpen, profileDropdownOpen)}>
                        <span className={header.transitionAnimation}>
                            <div className={header.profileimage} style={{ backgroundImage: `url(${adminImage})` }}></div>
                            <p className={`${header.transitionAnimation}`}>Hi, {JSON.parse(window.localStorage.getItem("currentUser")).name}</p>
                            <div className={`${header.dropDownProfile} ${profileDropdownOpen ? header.open : ""}`}>
                                <ul>
                                    <li onClick={() => handleMenuItemClick(setProfileDropdownOpen)}>Settings</li>
                                    <li onClick={() => handleMenuItemClick(setProfileDropdownOpen)}>Profile</li>
                                    <li onClick={() => handleMenuItemClick(setProfileDropdownOpen)}>Password</li>
                                    <li onClick={handleLogout}>Logout</li>
                                </ul>

                            </div>
                        </span>
                        <FaCaretDown className={`${header.downArrow} ${header.transitionAnimation}`} />
                    </div>
                    <div className={header.menuIcon} onClick={() => handleMenuToggle(setMenuOpen, menuOpen)}>
                        {menuOpen ? <IoClose className={header.transitionAnimation} /> : <HiOutlineMenu className={header.transitionAnimation} />}
                    </div>
                </div>
            </header>

            <nav>
                <ul className={`${header.listItemsContainer}  ${menuOpen ? header.open : ""}`}>
                    <NavItem to="/admin" onClick={() => handleMenuItemClick(setMenuOpen)}>Dashboard</NavItem>
                    <NavItem to="/admin/users" onClick={() => handleMenuItemClick(setMenuOpen)}>Users</NavItem>
                    {/* <NavItem to="/admin/packages" onClick={() => handleMenuItemClick(setMenuOpen)}>Packages</NavItem> */}
                    <NavItem to="/admin/blog" onClick={() => handleMenuItemClick(setMenuOpen)}>Blogs</NavItem>
                    <NavItem to="/admin/tours" onClick={() => handleMenuItemClick(setMenuOpen)}>Tours</NavItem>
                    <NavItem to="/admin/destination" onClick={() => handleMenuItemClick(setMenuOpen)}>Destinations</NavItem>
                    <NavItem to="/admin/inquiries" onClick={() => handleMenuItemClick(setMenuOpen)}>Inquires</NavItem>
                    <NavItem to="/admin/reservations" onClick={() => handleMenuItemClick(setMenuOpen)}>Reservations</NavItem>
                    <NavItem to="/admin/specialOffers" onClick={() => handleMenuItemClick(setMenuOpen)}>Special Offers</NavItem>
                    {/* <NavItem to="/admin/wishlist" onClick={() => handleMenuItemClick(setMenuOpen)}>Wishlist</NavItem> */}
                    <NavItem to="/admin/comments" onClick={() => handleMenuItemClick(setMenuOpen)}>Comments</NavItem>
                </ul>
            </nav>
        </div>
    );
};

export default AdminHeader;

export const NavItem = ({ children, to, onClick }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
        <Link to={to}><li className={`${header.navItem} ${header.transitionAnimation} ${isActive ? header.active : ""}`} onClick={onClick}>{children}</li></Link>
    );
};
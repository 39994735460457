import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import style from "./dashboardBlogPage.module.css";
import axios from 'axios';
import { api_url } from '../../../constants/base_url';
import { useQuery } from '@tanstack/react-query';
import Empty from '../../../components/empty/empty';
import AddLang from './lang/addLang';
import GetAllLangs from './lang/getAllLangs';

const DashboardBlogPage = () => {
    const { blogId } = useParams();
    const location = useLocation();
    const { title } = location.state || {};

    const [cardLang, setCardLang] = useState({});


    const { refetch } = useQuery({
        queryKey: ['blogCard'],
        queryFn: () =>
            axios.get(`${api_url}blog/adminShow/${blogId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }),
        onSuccess: (response) => {
            console.log("Data fetched successfully:", response.data);
        },
        onError: (err) => {
            console.error("Error fetching data:", err);
        }
    });


   


    return (
        <main className='mainContainer'>
            {cardLang ? <>
                <GetAllLangs blogId={blogId} setCardLang={setCardLang} title={title} />
            </>
                :
                <div className={style.empty}>
                    <Empty text={"Empty Blog"} />
                    <AddLang blogID={blogId} refetch={refetch} inEmpty />
                </div>
            }
        </main>
    )
}

export default DashboardBlogPage







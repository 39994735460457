function SectionHeading({ title, description, headingTextAlignment, rowAlignment, containerTextsStyle, children}) {
  return (
    <>
      <div className={`section-heading ${headingTextAlignment}`}>
        <div className={`row ${rowAlignment}`}>
          <div className={containerTextsStyle}>
            <h5 className="dash-style">{title}</h5>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionHeading;